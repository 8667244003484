<by-page-header
  [title]="'resend_email' | translate | capitalize"
></by-page-header>
<by-spin [nzSpinning]="loading$ | async">
  <ng-container *ngIf="reservation$ | async as reservation">
    <form nz-form [formGroup]="form" [nzLayout]="'vertical'">
      <ng-container *ngIf="reservation.booker?.detail?.email as customerEmail">
        <nz-form-item
          *ngIf="reservation.booker.id !== reservation.company?.customer.id"
        >
          <nz-form-control>
            <label nz-checkbox formControlName="sendEmailToCustomer">
              {{ 'send_email_to_customer' | translate | capitalize }}
              ({{ customerEmail }})
            </label>
          </nz-form-control>
        </nz-form-item>
      </ng-container>
      <nz-form-item
        *ngIf="reservation.company?.customer?.detail?.email as companyEmail"
      >
        <nz-form-control>
          <label nz-checkbox formControlName="sendEmailToCompany">
            {{ 'send_email_to_company' | translate | capitalize }}
            ({{ companyEmail }})
          </label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control>
          <label nz-checkbox formControlName="sendEmailToProperty">
            {{ 'send_email_to_property' | translate | capitalize }}
          </label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>
          {{ 'additional_emails' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control [nzErrorTip]="emailsErrors">
          <nz-select
            formControlName="additionalEmails"
            [nzPlaceHolder]="'additional_emails' | translate | capitalize"
            nzMode="tags"
          ></nz-select>
        </nz-form-control>
        <ng-template #emailsErrors let-control>
          <ng-container *ngIf="control.hasError('required')">
            {{
              'validation_form.required'
                | translate: { param: 'email' | translate }
                | capitalize
            }}
          </ng-container>
          <ng-container *ngIf="control.hasError('emailArrayValidator')">
            {{
              'validation_form.arrayemailvalidator'
                | translate: { param: 'email' | translate }
                | capitalize
            }}
          </ng-container>
        </ng-template>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>
          {{ 'template_system_type' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="templateSystemType"
            [nzDisabled]="!!templateSystemID"
          >
            <nz-option
              [nzLabel]="'system' | translate | capitalize"
              nzValue="system"
            ></nz-option>
            <nz-option
              [nzLabel]="'custom' | translate | capitalize"
              nzValue="custom"
              [nzDisabled]="!customTemplates.length"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="form.value.templateSystemType === 'custom'">
        <nz-form-control>
          <nz-select [nzAllowClear]="false" formControlName="templateCustomId">
            <nz-option
              *ngFor="let customTemplate of customTemplates"
              [nzLabel]="customTemplate.label"
              [nzValue]="customTemplate.id"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="form.value.templateSystemType === 'system'">
        <nz-form-control>
          <nz-select
            [nzAllowClear]="false"
            [nzDisabled]="!!templateSystemID"
            formControlName="templateSystemId"
          >
            <nz-option
              [nzLabel]="'confirm_reservation' | translate | upperfirst"
              [nzValue]="0"
            ></nz-option>
            <ng-container *ngFor="let systemTemplate of systemTemplates">
              <nz-option
                nzCustomContent
                *ngIf="systemTemplate.property_template?.active"
                [nzLabel]="systemTemplate.title"
                [nzValue]="systemTemplate.id"
              >
                {{ systemTemplate.title }}
                <ng-container
                  *ngIf="systemTemplate.day && systemTemplate.day_type"
                >
                  -
                  {{
                    'day_type_' + systemTemplate.day_type
                      | translate: { day: systemTemplate.day }
                  }}
                </ng-container>
              </nz-option>
            </ng-container>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-container>
</by-spin>
