import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { SubSink } from 'subsink';

import { EmailValidator } from '../../core/validators/email.validators';
import { IEmailTemplateSystem } from '../../features/commons/email-templates/models/email-template-system.model';
import { IEmailTemplateCustom } from '../../models';
import {
  EmailTemplatesCustomStoreActions,
  EmailTemplatesCustomStoreSelectors,
} from '../../root-store/email-templates-custom-store';
import {
  EmailTemplatesSystemStoreActions,
  EmailTemplatesSystemStoreSelectors,
} from '../../root-store/email-templates-system-store';
import {
  ReservationDetailsCustomActions,
  ReservationDetailsCustomSelectors,
} from '../../root-store/reservation-details-custom-store';
import { RootState } from '../../root-store/root-state';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

const resendReservationEmailFormValidator = (form: UntypedFormGroup) => {
  const {
    sendEmailToCustomer,
    sendEmailToCompany,
    sendEmailToProperty,
    additionalEmails,
  } = form.value;

  if (sendEmailToCustomer || sendEmailToCompany || sendEmailToProperty) {
    return null;
  }

  if (additionalEmails.length) {
    return null;
  }

  return {
    invalid: true,
  };
};

@Component({
  selector: 'by-resend-reservation-email-modal',
  templateUrl: './resend-reservation-email-modal.component.html',
  styleUrls: ['./resend-reservation-email-modal.component.scss'],
})
export class ResendReservationEmailModalComponent implements OnInit, OnDestroy {
  nzModalData: Partial<ResendReservationEmailModalComponent> =
    inject(NZ_MODAL_DATA);

  @Input() reservationId: number = this.nzModalData.reservationId || null;

  @Input() propertyId: number = this.nzModalData.propertyId;

  @Input() disableReservationLoad: boolean =
    this.nzModalData.disableReservationLoad || false;

  @Input() templateSystemID: any = this.nzModalData.templateSystemID || null;

  reservation$ = this.store.pipe(
    select(ReservationDetailsCustomSelectors.selectReservationDetails),
  );

  loading$ = this.store.pipe(
    select(ReservationDetailsCustomSelectors.selectReservationDetailsLoading),
  );

  customTemplates: IEmailTemplateCustom[] = [];

  systemTemplates: IEmailTemplateSystem[] = [];

  form = this.formBuilder.group(
    {
      sendEmailToCustomer: [false],
      sendEmailToCompany: [false],
      sendEmailToProperty: [false],
      additionalEmails: [[], [EmailValidator.emailArrayValidator]],
      templateSystemType: ['system'],
      templateCustomId: [null],
      templateSystemId: [null],
    },
    {
      validators: [resendReservationEmailFormValidator],
    },
  );

  private subs = new SubSink();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<RootState>,
  ) {}

  ngOnInit() {
    if (this.reservationId && !this.disableReservationLoad) {
      this.store.dispatch(
        ReservationDetailsCustomActions.loadRequest({
          reservationId: this.reservationId,
        }),
      );
    }

    if (this.propertyId) {
      this.store.dispatch(
        new EmailTemplatesCustomStoreActions.LoadRequestAction({
          propertyId: this.propertyId,
        }),
      );

      this.store.dispatch(
        EmailTemplatesSystemStoreActions.loadRequest({
          propertyId: this.propertyId,
          params: {
            type: ['reservations'],
          },
        }),
      );
    }

    this.subs.add(
      this.store
        .pipe(
          select(
            EmailTemplatesCustomStoreSelectors.selectEmailTemplatesCustomByType(
              'email',
            ),
          ),
        )
        .subscribe((customTemplates) => {
          this.customTemplates = customTemplates || [];
          this.setTemplate();
        }),
    );

    this.subs.add(
      this.store
        .pipe(select(EmailTemplatesSystemStoreSelectors.selectTemplates))
        .subscribe((systemTemplates) => {
          this.systemTemplates = systemTemplates || [];
        }),
    );

    this.subs.add(
      this.form
        .get('templateSystemType')
        .valueChanges.subscribe(() => this.setTemplate()),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();

    this.store.dispatch(
      new EmailTemplatesCustomStoreActions.ResetSuccessAction(),
    );
    this.store.dispatch(EmailTemplatesSystemStoreActions.resetState());
  }

  private setTemplate() {
    const { value: templateSystemType } = this.form.controls.templateSystemType;

    let templateCustomId = null;
    let templateSystemId = null;

    if (templateSystemType === 'custom') {
      templateCustomId = this.customTemplates[0]?.id;
    } else {
      templateSystemId = this.templateSystemID || 0;
    }

    this.form.patchValue(
      {
        templateCustomId,
        templateSystemId,
      },
      { emitEvent: false },
    );
  }
}
